body {
  margin: 0;
  font-family:
    "MessinaSans", system-ui, -apple-system, BlinkMacSystemFont,
    "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans",
    "Droid Sans", "Helvetica Neue", sans-serif;

  letter-spacing: -0.03em;
  font-feature-settings: "kern"; /* turns on kerning */
  text-rendering: optimizeLegibility; /* emphasise on legibility when rendering, turns on ligatures and kerning */
  -webkit-font-smoothing: antialiased; /* apply font anti-aliasing for Webkit on OSX */
  -moz-osx-font-smoothing: grayscale; /* apply font anti-aliasing for Firefox on OSX */
}

input,
textarea,
button {
  font-family: inherit;
  letter-spacing: inherit;
}

code {
  font-family: JetBrainsMono, ui-monospace, source-code-pro, Menlo, Monaco,
    Consolas, "Courier New", monospace;
}

img {
  max-width: 100%;
}

/* Useful global class for loading states */
.shimmer {
  background: #ededed;
  background-image: linear-gradient(
    to right,
    #ededed 0%,
    #e8e8e8 20%,
    #ededed 40%,
    #ededed 100%
  );
  background-repeat: no-repeat;
  background-size: 800px 800px;
  animation-duration: 1s;
  animation-fill-mode: forwards;
  animation-iteration-count: infinite;
  animation-name: placeholderShimmer;
  animation-timing-function: linear;
}

@keyframes placeholderShimmer {
  0% {
    background-position: -468px 0;
  }

  100% {
    background-position: 468px 0;
  }
}

a {
  text-decoration-line: none;
}

/* This exists in this file because MUI Datagrid doesn't yet use sx and we don't want to have to inject jss */
/* TODO (adam): Remove */
.warningCell {
  border-radius: 4px !important;
  border: 2px solid rgba(187, 48, 0, 1) !important;
  border-bottom: 2px solid rgba(187, 48, 0, 1) !important;
}
